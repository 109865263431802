* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.text-detailz {
  display: none;
}
.workers-form p {
  color: blue;
}
/* UpdateModal.css */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.estate-background {
  background-image: url("./component/image/estate6.jpg");
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-employees {
  width: 95%;
  margin: auto;
}
.all-employees-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.modal-content {
  background-color: white;
  padding: 20px;
  width: 25%;
}
.em-one {
  border: 1px solid #cccccc;
  height: 450px;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
}
.em-one-color {
  background-color: #0099cc;
  height: 150px;
}
.em-image {
  width: 80%;
  margin: auto;
}
.em-image img {
  width: 100%;
  margin-top: 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 210px;
}
.workers--details {
  width: 95%;
  margin: auto;
  max-height: 300px;
}
.workers-profile {
  text-align: center;
}
.workers-profile h5 {
  font-size: 16px;
}
.em-ployer {
  text-align: center;
  margin-top: 5px;
  color: #0099cc;
}
.em-details {
  width: 90%;
  margin: auto;
}
.em-details h5 {
  font-weight: 700;
}
.navi-gation {
  background-color: #f0f5f3;
  height: 54px;
}
.footer {
  background-color: red;
  height: 100px;
  width: 100%;
  display: none;
}
.estate select {
  width: 100%;
  padding: 5px 10px;
  outline: none;
  
}
.estate_logo {
  background-color: white;
  width: 100%;
  height: 100px;
}
input[type="file"] {
  border: none;
  outline: none;
}
.house_hold {
  width: 95%;
  margin: auto;
}
.household {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.house--det-tails {
  padding: 15px;
}
.house-hold-table {
  border: 1px solid #cccccc;
  height: 200px;
  width: 100%;
}
.payments-history-table {
  border: 1px solid #cccccc;
  height: 250px;
  width: 100%;
}
.modal_form {
  display: grid;
  grid-template-columns: auto auto;
  gap: 13px;
}
.pay_records {
  display: grid;
  grid-template-columns: auto auto;
  width: 85%;
  margin: auto;
  gap: 15px;
}
.bgg {
  width: 55%;
  padding: 15px;
  margin-bottom: 5px;
}
.bgg img {
  width: 100%;
  height: 70px;
}
.payment_recs {
  width: 75%;
  margin: auto;
}
.profile-section {
  width: 25%;
}
.pro_file {
  width: 75%;
}
.form_details {
  width: 91%;
  margin: auto;
  padding-top: 45px;
  justify-content: space-between;
  display: flex;
}
.details__image {
  width: 100%;
}
.details__image img {
  height: 500px;
  object-fit: cover;
}
.reply-btn {
  background: transparent;
  border: none;
  background-color: green;
  cursor: pointer;
  padding: 5px 10px;
  color: white;
}
.reply-area input {
  width: 100%;
  outline: none;
  border-radius: 32px;
}
.media_profile {
  display: none;
}
.arti-san-field___ textarea {
  width: 90%;
  height: 150px;
  outline: none;
}
.pro-file {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.all_residents-inputfield {
  display: flex;
  padding: 18px;
  justify-content: space-between;
}
.all_residents--inputfield {
  display: none;
  width: 95%;
  margin: auto;
}
.text-details {
  width: 100%;
}
.text---details input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
}
.setting--btn {
  background: transparent;
  border: none;
  padding: 5px 15px;
  background-color:#0099cc;
  color: white;
  font-weight: 700;
}
.setting---btn{
  background: transparent;
  border: none;
  padding: 5px 15px;
  background-color:#28a745;
  color: white;
  font-weight: 700;
}
.setting--btnn{
  background: transparent;
  border: none;
  padding: 5px 15px;
  background-color: red;
  color: white;
  font-weight: 700;
}
.barchart {
  width: 66%;
}
.user-ireport {
  padding: 30px;
}
.userreport-image {
  width: 45%;
}
.userreport-image img {
  width: 100%;
  height: 450px;
}
.user-ireport-item {
  display: flex;
  width: 94%;
  margin: auto;
  justify-content: space-between;
}
.user-ireport-text {
  width: 49%;
}
.user-date h5 {
  font-weight: 400;
}
.tabsTwwo {
  display: none;
}
.nav-barImage {
  width: 5%;
}
.nav-barImage img {
  width: 100%;
  border-radius: 16px;
}
.search_barr {
  width: 30%;
}
.nav_igation input {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 2px;
}
.main-panel {
  background-color: black;
  width: 25%;
  height: 950px;
  position: fixed;
  z-index: 1000;
  top: 0;
}
.qrButton {
  background: transparent;
  background-color: green;
  color: white;
  padding: 5px 10px;
  border: none;
  /* height: 50px; */
  font-weight: 600;
}
.panel-rows {
  display: flex;
  gap: 20px;
}
.dashboard {
  width: 85%;
  margin: auto;
  height: 470px; /* Adjust the height as per your layout */
  overflow-y: auto;
  padding-right: 10px;
}
.dash_board {
  height: 100%; /* Make the internal container take full height */
}
.dash_text h3 {
  font-size: 17px;
}
.dash_text p {
  width: 100%;
}
.dash2 {
  margin-top: -20px;
}
.border-line {
  border: 1px solid #cccccc;
  margin-top: -10px;
}
.forgotpassword input {
  width: 100%;
  margin-bottom: 25px;
}
.forgot-password h5 {
  font-size: 14px;
  margin-bottom: 15px;
}
.forgotpassword h4 {
  font-size: 16px;
}
.forgot-button {
  width: 100%;
  padding: 10px 15px;
  border: none;
  background: transparent;
  background-color: #0099cc;
  color: white;
  font-weight: 600;
}
.slanted-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #0099cc, #ccffcc);
  transform-origin: 0 0;
  transform: skewY(-12deg);
  z-index: -1;
}
.adminEdit-btn {
  padding: 5px 15px;
  border: none;
  outline: none;
  background: transparent;
  background-color: #0099cc;
  color: white;
  font-weight: 600;
}
.forgot-component {
  width: 30%;
  margin: auto;
}
.forgot-line {
  text-decoration: none;
}
.ff {
  font-size: 16px;
}
.panel-image img {
  width: 3%;
  border-radius: 13px;
}
.cardOne {
  background-color: #d5e2ee;
  height: 120px;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
}
.cardOne h5 {
  font-size: 14px;
}
.card-color {
  background-color: #ff9900;
  width: 22%;
  height: 32px;
  border-radius: 26px;
}
.card-colorTwo {
  background-color: #669906;
  width: 22%;
  height: 32px;
  border-radius: 26px;
}
.card-colorThr {
  background-color: orangered;
  width: 20%;
  height: 32px;
  border-radius: 26px;
}
.card-colorFr {
  background-color: #0099cc;
  width: 21%;
  height: 32px;
  border-radius: 26px;
}
.card-icon {
  padding-left: 8px;
}
.card--icon{
  padding-left: 10px;
}
.card-arr {
  color: orangered;
}
.cd1 h4 {
  font-size: 19px;
}
.cd1 h5 {
  font-size: 20px;
  font-weight: 700;
}
.containers {
  position: relative;
  border: 1px solid #cccccc;
  margin: auto;
  height: 420px;
  border-top: none;
  border-right: none;
  z-index: 0;
}
.con--tainers {
  position: relative;
  border: 1px solid #cccccc;
  width: 85%;
  margin: auto;
  height: 420px;
  border-top: none;
  border-right: none;
  z-index: 0;
}
.li-tags {
  width: 87%;
  margin-left: 30px;
}
.x-box {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  background-color: #669906;
  margin: auto;

  background: repeating-conic-gradient(
    from 0deg,
    #669906 0deg calc(3.6deg * 35),
    #ff9900 calc(3.6deg * 35) calc(3.6deg * 70),
    #0099cc calc(3.6deg * 70) calc(3.6deg * 100)
  );
}
.x--box {
  padding-top: 75px;
}
.x-box-cont {
  width: 90%;
  font-size: 13px;
  margin: auto;
  justify-content: space-between;
}
.x-box-cont h5 {
  font-size: 13px;
  text-transform: uppercase;
}
.x-box-cont strong {
  display: block;
  margin-bottom: 10px;
}
.rr {
  width: 30%;
}
.mhs h4 {
  font-size: 13px;
}
.form_details_media {
  display: none;
}
.line {
  text-decoration: none;
}
.users {
  width: 25%;
}
.a-btn {
  margin-top: -20px;
  font-weight: 600;
}
.dark {
  background-color: black;
  color: white;
}
.dark_btn {
  background: transparent;
  background-color: #0099cc;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: 600;
}
.b-g {
  display: none;
}
.bgg-g {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.bg1 {
  background-color: #d5e2ee;
  height: 120px;
  width: 100%;
}
.bloglist {
  width: 95%;
  margin: auto;
}
.signup-page {
  background-color: #0099cc;
  height: 100vh;
}
.estate-box-shadow {
  background-image: url(../src/component/image/estate.webp);
  height: 190vh;
  display: none;
}
.signup---page {
  padding-top: 30px;
}
.sign--up {
  width: 50%;
  margin: auto;
  height: 510px;
  background-color: white;
  border-radius: 16px;

}

.login-border {
  border: 0.3px solid #0099cc;
  height: 60px;
  width: 30%;
  margin: auto;
  border-radius: 10px;
}
.login-details {
  text-align: center;
  /* padding-top: 40px; */
}
.login---border {
  width: 30%;
  margin: auto;
  padding-top: 15px;
}
.irivs img {
  width: 100%;
}
.login-details p {
  font-size: 13px;
  font-weight: 600;
}
.login-form {
  width: 55%;
  margin: auto;
}
/* .login-form input {
  width: 100%;
  padding: 7px;
  outline: none;
  border: 1px solid red;
} */
.login--btn {
  border: none;
  background: transparent;
  background-color: #0099cc;
  color: white;
  cursor: pointer;
  width: 100%;
  padding: 5px 10px;
  font-weight: 500;
}
.login--options {
  justify-content: space-between;
}
.login--options h5 {
  font-size: 12px;
  font-weight: 700;
}
.remember-me {
  width: 100%;
}
.spawn-signin-page p {
  font-size: 13px;
}

.residents-form {
  width: 95%;
  margin: auto;
}
.residents_File {
  width: 75%;
}
.residents_File input {
  width: 100%;
  border: none;
}
.da_te input {
  width: 90%;
}
.sel__ect select {
  width: 100%;
}
.resident-txt-field {
  height: 40px;
  border: 1px solid #cccccc;
  background-color: #f0f5f3;
}
.residents-border {
  border: 1px solid #cccccc;
  height: auto;
  border-top: none;
}
.artisan-border {
  border: 1px solid #cccccc;
  height: auto;
  border-top: none;
}
.notification-border {
  border: 1px solid #cccccc;
  height: auto;
  border-top: none;
}
.notification-field textarea {
  min-height: 50px;
  height: auto;
  overflow-y: hidden;
  outline: none;
  background-color: #edf3f8;
}
.emergency-border {
  border: 1px solid #cccccc;
  height: auto;
  border-top: none;
}
.txt--inside {
  width: 97%;
  margin: auto;
}
.cisearch-icon h4 {
  font-size: 18px;
}
.cisearch-icon h4 {
  margin-top: 7px;
}
.residents-details {
  width: 90%;
  margin: auto;
  display: flex;
  gap: 10px;
  padding-top: 15px;
}
.text-details-owners {
  width: 25%;
}
.text-details-home h4 {
  font-size: 17px;
}
.text-details-owners select {
  width: 100%;
  padding: 5px 10px;
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
}
.text-details-owners h4 {
  font-size: 17px;
}
.residents-details-media {
  width: 95%;
  margin: auto;
  padding: 50px;
  display: none;
}
.arti-sanfield_ {
  display: flex;
  justify-content: space-between;
}
.residents-details-media input {
  width: 100%;
  height: 40px;
  outline: none;
}
.text-details h4 {
  font-size: 17px;
}
.text-details_ h4 {
  font-size: 17px;
}
.select-details h4 {
  font-size: 17px;
}
.text-details_ {
  width: 50%;
}
.sel-ects select {
  width: 100%;
  height: 45px;
  outline: none;
}
.date-section {
  width: 100%;
}
.date-section input {
  width: 100%;
  outline: none;
}
.text-details input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.text-details-occupation {
  width: 25%;
}
.text-details-occupation input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.text-details-occupation h4 {
  font-size: 17px;
}
.text-de_tails {
  width: 65%;
}
.text-de_tails input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.text-details select {
  padding: 7px 10px;
  background-color: #edf3f8;
  outline: none;
}
.select-details {
  width: 65%;
}
.select-details select {
  padding: 7px 10px;
  background-color: #edf3f8;
  outline: none;
  width: 100%;
}
.select-details input {
  padding: 7px 10px;
  background-color: #edf3f8;
  outline: none;
  width: 100%;
}
.sel-ect select {
  width: 100%;
}
.resident-button {
  width: 35%;
  margin: auto;
}
.residents_button {
  border: none;
  background: transparent;
  background-color: #669906;
  font-size: 16px;
  color: white;
  padding: 7px 10px;
  width: 100%;
  font-weight: 700;
}
.dropdown-btn {
  border: none;
  background: transparent;
  margin-top: 5px;
}
.reports-btn {
  border: none;
  background: transparent;
  margin-top: 5px;
  font-weight: 600;
}
.table {
  background-color: #d5e2ee;
}
.table th {
  color: #0099cc;
}
.media_table {
  background-color: #d5e2ee;
  display: none;
}
.media_table th {
  color: #0099cc;
}
.pending-box {
  background-color: red;
  width: 9%;
  height: 17px;
  margin-top: 6px;
  border-radius: 4px;
}
.all-residents_ {
  border: 1px solid #cccccc;
  height: 70px;
  /* border-top: none; */
}
.config_border {
  border: 1px solid #cccccc;
  height: 150vh;
  border-top: none;
}
.config__border {
  width: 95%;
  margin: auto;
}
.config_box {
  display: flex;
  justify-content: space-between;
}
.config-button {
  padding: 5px 10px;
  background-color: #669906;
  font-weight: 600;
  border: none;
  color: white;
}
.pagination-btn {
  border: none;
  padding: 5px 10px;
  background-color: green;
  color: white;
}
.configform input {
  padding: 4px 10px;
  width: 100%;
  background-color: #edf3f8;
  outline: none;
  border: 1px solid #cccccc;
}
.form-form {
  width: 95%;
  margin: auto;
  padding-top: 25px;
}
.settings_input {
  width: 35%;
}
.settings-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}
.boxx_settings {
  width: 100%;
  margin-bottom: 25px;
}
.dropdown__details {
  display: flex;
  justify-content: space-between;
}
.dropdownEdit__details {
  display: flex;
  justify-content: space-between;
}
.boxx_ {
  width: 30%;
}
.box_1 {
  border: 1px solid #cccccc;
  height: 300px;
  margin-bottom: 5px;
}
.editupdate{
  text-align: center;
  margin-top: 100px;
}
.report-cell {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box_line {
  padding: 10px;
  border-bottom: 1px solid #cccccc;
}
.box_line_settings {
  padding: 25px;
}
.box_1details {
  overflow-y: scroll;
}
.all-residents__ {
  border: 1px solid #cccccc;
  height: 195px;
  border-top: none;
}
.input-field input {
  padding: 4px 9px;
  background-color: #edf3f8;
  outline: none;
  border: 1px solid #cccccc;
  width: 100%;
}
.all__residents-btn {
  border: none;
  background: transparent;
  background-color: #669906;
  cursor: pointer;
  padding: 10px 10px;
  color: white;
  font-weight: 700;
  font-size: 14px;
}
.com-card-one {
  background-color: #0099cc;
  height: 140px;
  width: 30%;
  border-radius: 4px;
}
.com-card-two {
  background-color: #ff9900;
  height: 140px;
  width: 30%;
  border-radius: 4px;
}
.com-card-three {
  background-color: #669906;
  height: 140px;
  width: 30%;
  border-radius: 4px;
}
.compliance-border {
  border: 1px solid #cccccc;
  height: 100vh;
}
.compliance-details h4 {
  font-size: 18px;
}
.in-put-one {
  width: 25%;
}
.in-put-one input {
  padding: 4px 9px;
  width: 100%;
  background-color: #edf3f8;
  outline: none;
  border: 1px solid #cccccc;
}
.in-put-one select {
  padding: 5px 9px;
  width: 100%;
  background-color: #f0f5f3;
  outline: none;
}
form.example input {
  padding: 5px;
  font-size: 17px;
  border: 1px solid #cccccc;
  float: left;
  width: 80%;
  background: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: none;
  outline: none;
}
form.example button {
  float: left;
  width: 20%;
  padding: 5px;
  background: #cccccc;
  color: white;
  font-size: 17px;
  border: 1px solid #cccccc;
  border-left: none;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  outline: none;
}
form.example button:hover {
  background: #0099cc;
  color: white;
}
.error {
  color: red;
  font-size: 12px;
}
.bar--chat {
  width: 100%;
  height: 450px;
  border: 1px solid #cccccc;
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.ba-rr {
  width: 1%;
  background-color: #669906;
}
.login-button p {
  color: red;
}
.check--box input {
  width: 15%;
  margin-top: -9px;
}
.check--box {
  gap: 5px;
}
.emergencyfile {
  justify-content: space-between;
}
.input-onee {
  width: 54%;
}
.password-page {
  width: 45%;
  margin: auto;
  height: 500px;
  margin-top: 50px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.generate_input_field input {
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  background-color: #edf3f8;
  width: 100%;
}
.edit-form input {
  width: 100%;
}
.edit-form textarea {
  width: 100%;
  outline: none;
}
.reset-text {
  width: 45%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}
.copy {
  padding-top: 60px;
  width: 95%;
  margin: auto;
  font-style: italic;
  font-size: 14px;
}
.reset-input {
  width: 70%;
  margin: auto;
}
.reset-input input {
  width: 100%;
}
.reset-password-btn {
  width: 100%;
  padding: 10px 10px;
  border: none;
}
.generate_input_field {
  width: 27%;
}
.generate_select select {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  background-color: #edf3f8;
  outline: none;
}
.password-icon {
  position: absolute;
  left: 90%;
  top: 30%;
  /* margin-top: 28px; */
}
.details_image img {
  width: 100%;
}
.ud {
  justify-content: space-between;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.user-input-fieldd {
  display: none;
}
.te input {
  background-color: #edf3f8;
  border: 1px solid #cccccc;
}
.tee input {
  background-color: #edf3f8;
  border: 1px solid #cccccc;
}
.sel-ects select {
  background-color: #edf3f8;
  border: 1px solid #cccccc;
}
.fadeInOut {
  animation: fadeInOut 3s infinite;
}
.profile__image img {
  height: 230px;
  width: 100%;
  object-fit: cover;
}
.hamburger-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.user_date input {
  background-color: #edf3f8;
}
.text-area textarea {
  background: #edf3f8;
}
.line {
  width: 30px;
  height: 2px;
  background-color: #333;
}
.menu {
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  background-color: #f5f5f5;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}
.hamburger-menu.open .menu {
  transform: translateX(0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  color: #333;
  font-weight: bold;
}

li:last-child {
  border-bottom: none;
}
.ham {
  display: none;
}

.media_card_section {
  display: flex;
  justify-content: space-between;
  width: 27%;
  display: none;
}
.mediaCard_sec1 {
  background-color: #d5e2ee;
  height: 100px;
  width: 45%;
}
.mediaCard_sec2 {
  background-color: #d5e2ee;
  height: 100px;
  width: 45%;
}
.mediaCard_sec3 {
  background-color: #d5e2ee;
  height: 100px;
  width: 45%;
}
.mediaCard_sec4 {
  background-color: #d5e2ee;
  height: 100px;
  width: 45%;
}
.users_ {
  width: 75%;
}
.notification-bar {
  width: 25%;
}
.notification-content {
  width: 75%;
}
.notification_content {
  width: 95%;
  margin: auto;
}
.notification-bcolor {
  width: 100%;
  height: 300px;
  background-color: #dcf8c6;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.notification-info {
  max-height: 100px;
  word-wrap: break-word;
  flex-grow: 1;
}
.notification-bcolor::after {
  content: "";
  /* position: absolute; */
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #dcf8c6 transparent transparent transparent;
}
.us-ers {
  width: 95%;
  margin: auto;
}
.user-input-field {
  width: 95%;
  margin: auto;
}
.user-input-field_ {
  display: none;
  width: 95%;
  margin: auto;
}
.profile-details {
  width: 55%;
}
.profile-section {
  width: 25%;
}
.da_te {
  width: 54%;
}
.artisan_details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
.emergency_details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
.active {
  background-color: green;
  border: 1px solid black;
  color: white;
  padding: 5px 10px;
  font-weight: 600;
}
.inactive {
  background-color: red;
  border: 1px solid black;
  color: white;
  padding: 5px 10px;
  font-weight: 600;
}
.artisan_info {
  display: flex;
  justify-content: flex-end;
  width: 85%;
}
.noti-full-info {
  width: 95%;
  margin: auto;
}
.artisanImage {
  width: 75%;
  margin: auto;
}
.activeButton {
  background-color: green;
}
.artisanImage img {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 190px;
  margin-top: 25px;
}
.artisan_details_ {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 50px;
  display: none;
}
.border {
  width: 100%;
  border: 1px solid red;
  height: auto;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.bor-der {
  width: 100%;
  height: 74vh;
  border-top: none;
  border: 1px solid #cccccc;
  margin-bottom: 50px;
}
.user-msg-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  color: blue;
}
.info--btn {
  background: transparent;
  border: none;
  color: black;
}
.info---btn {
  color: black;
  margin-top: -11px;
}
.card_one {
  background-color: #0099cc;
  height: 150px;
  margin-bottom: 300px;
}
.card_Two {
  background-color: #fe9900;
  height: 150px;
}
.dashboard-border {
  width: 90%;
  margin: auto;
}
.users-dash-board {
  width: 90%;
  margin: auto;
}
.dashboard--border {
  border: 1px solid #d5e2ee;
  min-height: 180px; /* Set a minimum height */
  background-color: #d5e2ee;
}
.send__report {
  width: 20%;
}
.dash_nav {
  width: 80%;
}
.artisan_left {
  width: 25%;
}
.artisan_field {
  width: 95%;
  margin: auto;
}
.atisan--input-field {
  width: 50%;
  margin: auto;
  padding-top: 50px;
}
.notification--input-field {
  width: 50%;
  margin: auto;
  padding-top: 50px;
}
.notification--input-field input {
  width: 100%;
  background-color: #edf3f8;
}
.notification--input-field textarea {
  width: 100%;
}
.emergency--input-field {
  width: 50%;
  margin: auto;
  padding-top: 50px;
}
.atisan--inputfield {
  width: 85%;
  margin: auto;
  padding: 50px;
}
.input-field {
  width: 30%;
}
.report__feild_ {
  width: 50%;
  margin: auto;
}
.text-area textarea {
  width: 100%;
  height: 150px;
  outline: none;
}
.sendreport-border {
  border: 1px solid #d5e2ee;
  width: 97%;
  margin: auto;
  height: 570px;
}
.report_but-ton {
  background: transparent;
  color: white;
  border: none;
  background-color: #669906;
  padding: 5px 15px;
  font-weight: 700;
}
.radio_btn {
  display: flex;
  justify-content: space-between;
  width: 45%;
}
.user_date input {
  outline: none;
  padding: 5px 10px;
}
.pen-ding {
  display: flex;
  justify-content: space-between;
  width: 20%;
}
.arti-san-field {
  width: 90%;
  display: flex;
}
.arti-san-field input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.emergency-field input {
  background-color: #edf3f8;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.emergency-field {
  width: 90%;
  display: flex;
}
.arti-san-field textarea {
  width: 100%;
  background-color: #edf3f8;
  height: 150px;
  outline: none;
}
.residents___File {
  width: 63%;
}
.artisan-border_ {
  display: none;
  border: 1px solid #cccccc;
  height: auto;
  border-top: none;
}
.adminbaord-text {
  padding: 45px;
}
.adminbaord-text h5 {
  font-family: "Josefin Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.4em;
}
.emergencyfile {
  display: flex;
}
.admin--cardholder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  margin: auto;
  gap: 25px;
}
.admin-card {
  background-color: #d5e2ee;
  height: 200px;
  width: 100%;
}
.admin-cardOne {
  width: 100%;
}
.admin-card_One {
  width: 25%;
}
.admin__cards {
  display: none;
}
.super-admin-side-bar {
  width: 25%;
}
.super-text {
  text-align: center;
  padding-top: 10px;
}
.super-text h5 {
  color: white;
}
.super-color {
  background-color: #d5e2ee;
  height: 700px;
  width: 75%;
}
.super-input {
  width: 85%;
  margin: auto;
  padding-top: 25px;
}
.super-input-One input {
  width: 65%;
  padding: 3px;
}
.superAdmin-nav {
  background-color: #f0f6f5;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.sub-admin-form {
  width: 60%;
}
.subAdmin input {
  width: 100%;
  outline: none;
}
.sub-details {
  width: 65%;
}
.sub-details input {
  width: 100%;
  padding: 4px;
  outline: none;
}
.sub-details select {
  width: 100%;
  padding: 4px;
  outline: none;
}
.subAdmin-btn {
  outline: none;
  background: transparent;
  padding: 5px 10px;
  color: white;
  background-color: #669906;
  border: none;
  font-weight: 500;
}
.main--side {
  width: 90%;
}
.form-group {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}
.form-group label {
  font-weight: 600;
}
label {
  margin-right: 10px;
}

input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}
.form {
  width: 30%;
  margin: auto;
}
.resident-image {
  width: 10%;
  margin: auto;
}
.resident-image img {
  width: 100%;
}
.profile-btn {
  border: 1px solid black;
  background: transparent;
  padding: 5px 15px;
  color: black;
}
.profile-btnn {
  background: transparent;
  padding: 5px 21px;
  border: 1px solid black;
  color: black;
}
.artisan--cardone {
  width: 100%;
  background-color: #0099cc;
  color: white;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.artisan--cardtwo {
  width: 100%;
  background-color: #fe9900;
  color: white;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.3s ease; */
}
.artisan--cardthree {
  width: 100%;
  background-color: #669900;
  color: white;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.artisan-iconztwo {
  color: black;
}
.artisan--cardfour {
  width: 100%;
  background-color: royalblue;
  color: white;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.3s ease; */
}
.artisan--cardfv {
  width: 100%;
  background-color: red;
  color: white;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.card-content {
  padding-top: 5px;
  width: 75%;
}
.artisan-iconone {
  width: 12%;
  height: 35px;
  margin-top: 10px;
}
.artisan--flex {
  justify-content: space-between;
  width: 95%;
  margin: auto;
}
.card-content p {
  font-weight: 600;
  font-size: 18px;
}
.dashboard-border h4 {
  font-size: 17px;
}
.notify-form {
  width: 85%;
  margin: auto;
}
.notify-inputone input {
  width: 100%;
}
.notify-inputone textarea {
  width: 100%;
  height: 100px;
}
.notify-btn {
  background: none;
  border: none;
  padding: 5px 10px;
  background-color: green;
  color: white;
  font-weight: 600;
}
.notify-border--media {
  display: none;
}
.artisan--search {
  display: none;
}
.artisan--search- {
  display: none;
}
.modal_form_media {
  display: none;
}
.edit_section {
  width: 70%;
  margin: auto;
}
.emp-form {
  width: 95%;
  margin: auto;
}
.nav_gation {
  width: 97%;
  margin: auto;
  padding-top: 10px;
}
.employees_field {
  width: 95%;
  margin: auto;
}
.workers-form p {
  margin-top: -20px;
  font-size: 12px;
}
.workersbtn {
  border: none;
  background: transparent;
  background-color: green;
  color: white;
  cursor: pointer;
  padding: 5px 15px;
  font-weight: 700;
}
.employers-profile h6 {
  color: #0099cc;
}
.input--wrappa {
  width: 45%;
}
.input--wrappa2 {
  width: 45%;
}
.input-wrapper input {
  width: 100%;
}
.input-wrapper select {
  width: 100%;
  outline: none;
  appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.input-wrap {
  justify-content: space-between;
  display: flex;
}
.input-wrapper {
  margin-bottom: 20px;
}
input[type="text"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}
.input-select {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  background-color: transparent;
  width: 100%;
}
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.notification-edit-btn {
  border: none;
  background: transparent;
  background-color: #0099cc;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 8px;
  display: block;
  align-self: flex-end;
}
.check_box {
  justify-content: space-between;
}
.large-checkbox {
  width: 20px;
  height: 20px;
}
.txt-area textarea {
  width: 100%;
  height: 100px;
  outline: none;
}
.txt-area input {
  margin-bottom: 10px;
}

.search-table {
  width: 95%;
  margin: auto;
}
.input__wrappa {
  justify-content: space-between;
}
.scrollable {
  max-height: 400px;
  overflow-y: auto;
}
.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.msg-input input {
  width: 95%;
  margin: auto;
}
.button-spacing {
  margin-right: 10px;
}
.compliance-cards{
  display: flex;
  justify-content: space-between;
}
.search-house{
  width: 95%;
  margin: auto;
}
.searchhousebtn{
  border: none;
  background: transparent;
  background-color: #669906;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.search-house input{
  margin-bottom: 10px;
}
.user-noti-main{
  width: 75%;
}
.user-noti-main h4{
  padding: 10px;
  color: #0000ff;
}
.footer--side{
  background-color: black;
  max-height: auto;
  width: 100%;
}
.footer-text{
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  color: white;
}
.text-one{
  color: white;
  font-size: 11px;
}
@media (max-width: 425px) {
  .rr {
    display: none;
  }
  .nav_gation {
    width: 96%;
    margin: auto;
  }
  .dashboard--border {
    border: 1px solid #d5e2ee;
    min-height: 180px;
    background-color: #d5e2ee;
  }
  .resident-button {
    width: 50%;
  }
  .notify-border--media {
    display: block;
  }
  .artisan-iconone {
    width: 10%;
  }
  .media__cards {
    display: none;
  }
  .notify-field input {
    width: 100%;
    background-color: #d5e2ee;
  }
  .notify-field textarea {
    width: 100%;
    height: 150px;
    background-color: #d5e2ee;
    outline: none;
  }
  .artisan--search-button {
    display: block;
  }
  .artisan--search-btn {
    padding: 5px 10px;
    border: none;
    color: white;
    background-color: green;
    outline: none;
  }
  .notify-button {
    width: 20%;
    margin: auto;
  }
  .notify--btn {
    padding: 5px 10px;
    border: none;
    color: white;
    background-color: green;
    font-weight: 600;
    outline: none;
  }
  .household {
    display: block;
  }
  .house-hold-table {
    margin-bottom: 25px;
  }
  .config_box {
    display: block;
  }
  .boxx_ {
    width: 100%;
  }
  .config_border {
    height: 157vh;
  }
  .generate_input_field {
    width: 100%;
    margin-bottom: 25px;
  }
  .generate_input_field input {
    width: 100%;
  }
  .forgot-component {
    width: 85%;
  }
  .form_details {
    display: block;
  }
  .main--side {
    width: 100%;
  }
  .super-color {
    width: 100%;
  }
  .table__records {
    overflow-x: auto;
    margin-top: 7px;
  }
  .col {
    display: none;
  }
  .table {
    width: 100%;
  }
  .table th,
  .table td {
    white-space: nowrap;
    min-width: 100px;
  }
  .form_details {
    display: none;
  }
  .form_details_media {
    display: block;
    padding-top: 30px;
    width: 90%;
    margin: auto;
  }
  .artisan--search {
    display: block;
  }
  .example {
    display: none;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
  }
  .ham {
    display: block;
    margin-top: 10px;
  }
  .media_card {
    margin-top: -70px;
    margin-bottom: 25px;
  }
  .media_1 {
    padding: 7px;
  }
  .media_card_section {
    display: flex;
    width: 100%;
  }
  .mediaCard_sec1 {
    margin-bottom: 10px;
  }
  .mediaCard_sec3 {
    margin-bottom: 10px;
  }
  .tabsTwo {
    display: none;
  }
  .barchart {
    width: 100%;
  }
  .tabsTwwo {
    display: block;
    margin-top: -140px;
  }

  .x-box {
    width: 30%;
    height: 120px;
  }
  .pending__reports {
    margin-top: 80px;
  }
  .pending__reports h5 {
    font-size: 10px;
    font-weight: 700;
    margin-top: 2px;
  }
  .pending-box {
    height: 9px;
    width: 10%;
    margin-top: 85px;
  }
  .users {
    display: none;
  }
  .users_ {
    width: 100%;
  }
  .all_residents-inputfield {
    display: none;
  }
  .user-input-field {
    display: none;
  }
  .user-input-field_ {
    display: block;
  }
  .in-put-one {
    width: 100%;
  }
  .in-put-one input {
    width: 100%;
  }
  .all-residents__ {
    height: 270px;
  }
  .com-card-one h5 {
    font-size: 18px;
  }
  .com-card-two h5 {
    font-size: 18px;
  }
  .com-card-three h5 {
    font-size: 16px;
  }
  .residents-details {
    width: 95%;
    margin: auto;
    display: none;
  }
  .residents-details-media {
    display: block;
  }
  .residents_button {
    font-size: 11px;
  }
  .date-section {
    width: 67%;
  }
  .sel-ect {
    width: 69%;
  }
  .residents_File input {
    width: 100%;
  }
  .all_residents--inputfield {
    display: block;
    margin-top: 20px;
  }
  .media_input {
    width: 95%;
    display: flex;
    gap: 15px;
  }
  .resident-media-input input {
    width: 100%;
  }
  .resident-media-btn {
    border: none;
    padding: 5px 20px;
    background: transparent;
    background-color: green;
    color: white;
    font-weight: 600;
  }
  .media_input2 input {
    width: 90%;
  }
  .all-residents_ {
    height: 125px;
  }
  .main-panel {
    height: 700px;
    width: 100%;
  }
  .dropdown___details {
    width: 55%;
  }
  .dropdown___details_ {
    width: 83%;
  }
  .dropdown___details__ {
    width: 70%;
  }
  .drop__down {
    height: 160px;
  }
  .profile-details {
    display: none;
  }
  .media_profile {
    display: block;
    width: 95%;
    margin: auto;
    margin-top: 60px;
    display: flex;
  }
  .med_profile input {
    background-color: #edf3f8;
    padding: 5px 10px;
    border: 1px solid #cccccc;
    outline: none;
  }
  .all_mediainput {
    margin-top: 70px;
  }
  .profile-section {
    display: none;
  }
  .residents-border {
    max-height: 500px;
    overflow-y: auto;
  }
  .media__profile-section {
    width: 35%;
    margin-top: -85px;
  }
  .signup-page {
    display: none;
  }
  .sign--up {
    width: 95%;
  }
  .login-border {
    width: 45%;
  }
  .login-form {
    width: 75%;
  }
  .password-icon {
    position: absolute;
    left: 75%;
    top: 47%;
    display: none;
  }
  .profile__image {
    width: 100%;
  }
  .profile__image img {
    height: 150px;
  }
  .da_te {
    width: 100%;
  }
  .da_te input {
    width: 100%;
  }
  .pen-ding {
    width: 25%;
  }
  .user-input-fieldd {
    display: block;
  }
  .artisan_left {
    display: none;
  }
  .artisan-border {
    display: none;
  }
  .artisan-border_ {
    display: block;
  }
  .artisan_details {
    display: none;
  }
  .emergency_details {
    display: block;
  }
  .artisan_details_ {
    display: block;
  }
  .artisanImage img {
    height: 250px;
  }
  .report__feild_ {
    width: 90%;
  }
  .radio_btn {
    width: 60%;
  }
  .sendreport-border {
    height: 105vh;
  }
  .admin--cardholder {
    display: none;
  }
  .admin__cards {
    display: block;
    width: 95%;
    margin: auto;
  }
  .admin-card_One {
    width: 100%;
  }
  .adminbaord-text {
    padding: 85px;
  }
  .sub-admin-form {
    width: 100%;
  }
  .x-box-cont {
    width: 40%;
    margin: auto;
  }
  .card-color {
    height: 28px;
    margin-top: 11px;
    width: 16%;
    margin-right: 5px;
  }
  .card-colorTwo {
    height: 28px;
    margin-top: 11px;
    width: 16%;
    margin-right: 5px;
  }
  .card-colorThr {
    height: 29px;
    margin-top: 11px;
    width: 16%;
    margin-right: 5px;
  }
  .card-colorFr {
    height: 29px;
    margin-top: 11px;
    width: 16%;
  }
  .card-icon {
    padding-right: 5px;
  }
  .settings_input {
    width: 100%;
  }
  .settings-grid {
    display: block;
  }
  .user-ireport-item {
    display: block;
    width: 85%;
    margin: auto;
  }
  .userreport-image {
    width: 100%;
  }
  .user-ireport-text {
    width: 100%;
  }
  .form {
    width: 90%;
    margin: auto;
  }
  .resident-image {
    width: 45%;
  }
  .resident-image img {
    height: 150px;
  }
  .border-media {
    border: 1px solid #eef3f9;
    height: auto;
    overflow: auto;
    margin-bottom: 25px;
  }
  .artisan--search input {
    width: 100%;
  }
  .main-panel {
    width: 95%;
  }
  .text-detailz {
    display: block;
  }
  .text-detailz input {
    width: 100%;
    background-color: #eef3f9;
  }
}

@media (max-width: 428px) {
  .scrollable {
    max-height: 500px;
    overflow-y: auto;
  }
  .rr {
    display: none;
  }
  .config_box {
    display: block;
  }
  .remember-me{
    /* margin-top: -5px; */
  }
  .artisan-iconone {
    height: 45px;
  }
  .artisan-iconz {
    font-size: 21px;
  }
  .artisan-iconztwo {
    font-size: 21px;
  }
  .boxx_ {
    width: 100%;
  }
  .modal_form {
    display: none;
  }
  .modal_form_media {
    display: block;
  }
  .modal_form_media input {
    height: 45px;
  }
  .custom-datepicker {
    width: 100%;
  }
  .artisan_text textarea {
    width: 100%;
  }
  .config_border {
    max-height: 500px;
    overflow-y: auto;
  }
  .dropdown-btn {
    width: 100%;
  }
  .not-fications {
    justify-content: flex-end;
  }
  .generate_input_field {
    width: 100%;
    margin-bottom: 25px;
  }
  .generate_input_field input {
    width: 100%;
  }
  .form_details {
    display: block;
  }
  .main--side {
    width: 100%;
  }
  .media__cards {
    display: none;
  }
  .super-color {
    width: 100%;
  }
  .table__records {
    overflow-x: auto;
    margin-top: 7px;
  }
  .col {
    display: none;
  }
  .table {
    width: 100%;
  }
  .table th,
  .table td {
    white-space: nowrap;
    min-width: 100px;
  }
  .form_details {
    display: none;
  }
  .form_details_media {
    display: block;
    padding-top: 30px;
    width: 90%;
    margin: auto;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
  }
  .ham {
    display: block;
    margin-top: 10px;
  }
  .media_card {
    margin-top: -70px;
    margin-bottom: 25px;
  }
  .media_1 {
    padding: 7px;
  }
  .media_card_section {
    display: flex;
    width: 100%;
  }
  .mediaCard_sec1 {
    margin-bottom: 10px;
  }
  .mediaCard_sec3 {
    margin-bottom: 10px;
  }
  .tabsTwo {
    display: none;
  }
  .barchart {
    width: 100%;
  }
  .tabsTwwo {
    display: block;
    margin-top: -140px;
  }
  .x-box {
    width: 30%;
    height: 120px;
  }
  .pending__reports {
    margin-top: 80px;
  }
  .pending__reports h5 {
    font-size: 10px;
    font-weight: 700;
    margin-top: 2px;
  }
  .pending-box {
    height: 9px;
    width: 10%;
    margin-top: 85px;
  }
  .users {
    display: none;
  }
  .users_ {
    width: 100%;
  }
  .all_residents-inputfield {
    display: none;
  }
  .user-input-field {
    display: none;
  }
  .user-input-field_ {
    display: block;
  }
  .in-put-one {
    width: 100%;
  }
  .in-put-one input {
    width: 100%;
  }
  .all-residents__ {
    height: 270px;
  }
  .com-card-one h5 {
    font-size: 18px;
  }
  .com-card-two h5 {
    font-size: 18px;
  }
  .com-card-three h5 {
    font-size: 16px;
  }
  .residents-details {
    display: none;
  }
  .resident-media-btn {
    border: none;
    color: white;
    background: transparent;
    background-color: #669906;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: 600;
  }
  .residents-details-media {
    display: block;
    width: 100%;
  }
  .residents_button {
    font-size: 11px;
  }
  .resident-button {
    font-size: 22px;
    font-weight: 700;
  }
  .date-section {
    width: 67%;
  }
  .sel-ect {
    width: 69%;
  }
  .residents_File {
    width: 70%;
  }
  .all_residents--inputfield {
    display: block;
    margin-top: 20px;
  }
  .media_input {
    width: 95%;
    display: flex;
    gap: 15px;
  }
  .input-field {
    width: 51%;
  }
  .input-field input {
    width: 100%;
  }
  .resident-media-input input {
    width: 100%;
  }
  .media_input2 input {
    width: 90%;
  }
  .all-residents_ {
    height: 125px;
  }
  .main-panel {
    height: 700px;
    width: 100%;
  }
  .dropdown___details {
    width: 55%;
  }
  .dropdown___details_ {
    width: 83%;
  }
  .edit_section {
    width: 100%;
  }
  .dropdown___details__ {
    width: 70%;
  }
  .drop__down {
    height: 160px;
  }
  .profile-details {
    display: none;
  }
  .media_profile {
    display: block;
    width: 95%;
    margin: auto;
    margin-top: 60px;
    display: flex;
  }
  .med_profile input {
    background-color: #edf3f8;
    padding: 5px 10px;
    border: 1px solid #cccccc;
    outline: none;
  }
  .all_mediainput {
    margin-top: 70px;
  }
  .profile-section {
    display: none;
  }
  .residents-border {
    height: auto;
  }
  .media__profile-section {
    width: 35%;
    margin-top: -85px;
  }
  .signup-page {
    height: 650px;
  }
  .sign--up {
    width: 95%;
  }
  .login-border {
    width: 45%;
  }
  .login-form {
    width: 70%;
  }
  .password-icon {
    position: absolute;
    left: 75%;
    top: 47%;
    display: none;
  }
  .profile__image {
    width: 100%;
  }
  .profile__image img {
    height: 150px;
  }
  .da_te {
    width: 100%;
  }
  .da_te input {
    width: 100%;
  }
  .pen-ding {
    width: 25%;
  }
  .user-input-fieldd {
    display: block;
  }
  .artisan_left {
    display: none;
  }
  .artisan-border {
    display: none;
  }
  .artisan-border_ {
    display: block;
  }
  .artisan_details {
    display: none;
  }
  .artisan_details_ {
    display: block;
  }
  .artisanImage img {
    height: 250px;
  }
  .report__feild_ {
    width: 90%;
  }
  .radio_btn {
    width: 60%;
  }
  .sendreport-border {
    height: 105vh;
  }
  .admin--cardholder {
    display: none;
  }
  .admin__cards {
    display: block;
    width: 95%;
    margin: auto;
  }
  .admin-card_One {
    width: 100%;
  }
  .adminbaord-text {
    padding: 85px;
  }
  .sub-admin-form {
    width: 100%;
  }
  .x-box-cont {
    width: 40%;
    margin: auto;
  }
  .card-color {
    height: 28px;
    margin-top: 11px;
    width: 22%;
    margin-right: 5px;
  }
  .card-colorTwo {
    height: 28px;
    margin-top: 11px;
    width: 20%;
    margin-right: 5px;
  }
  .card-colorThr {
    height: 29px;
    margin-top: 11px;
    width: 20%;
    margin-right: 5px;
  }
  .card-colorFr {
    height: 29px;
    margin-top: 11px;
    width: 16%;
    margin-right: 5px;
  }
  .card-icon {
    padding-right: 5px;
  }
  .not-fications {
    width: 25%;
    padding-right: 5px;
  }
  .nav_settings {
    width: 7%;
  }
  .main-panel {
    width: 95%;
  }
  .border-media {
    border: 1px solid #eef3f9;
    height: auto;
    overflow: auto;
    margin-bottom: 25px;
  }
  .nav_gation {
    width: 96%;
    margin: auto;
  }
  .emergency_details {
    display: block;
  }
  .atisan--inputfield {
    width: 100%;
  }
  .arti-san-field {
    width: 100%;
  }
  .notification-field {
    width: 100%;
  }
  .notification-field input {
    background-color: #edf3f8;
    padding: 5px 10px;
    border: 1px solid #cccccc;
    outline: none;
    width: 100%;
  }
  .arti-san-field___ textarea {
    width: 100%;
  }
  .notification--input-field {
    width: 95%;
    margin: auto;
  }
  .emergency-field {
    display: block;
    width: 100%;
  }
  .emergency--input-field {
    width: 95%;
    margin: auto;
  }
  .residents_button {
    font-size: 10px;
    padding: 10px 10px;
  }
  .artisan-border {
    display: block;
  }
  .arti-san-field {
    display: block;
  }
  .atisan--input-field {
    width: 95%;
    margin: auto;
  }
  .emergencyfile {
    display: block;
  }
  .form {
    width: 95%;
    margin: auto;
  }
  .resident-image img {
    width: 100%;
    height: 200px;
  }
  .resident-image {
    width: 60%;
    margin: auto;
  }
  .forgot-component {
    width: 95%;
  }
  .password-page {
    width: 95%;
  }
  .reset-text {
    width: 90%;
  }
  .reset-input {
    width: 90%;
  }
  .text-detailz {
    width: 95%;
    margin: auto;
  }
  .text-detailz input {
    padding: 10px;
    width: 100%;
    background-color: #eef3f9;
  }
  .pending-box {
    width: 15%;
    padding: 10px;
  }
  .pending__reports h5 {
    font-size: 17px;
  }
  .pen-ding {
    width: 41%;
  }
  .check--box {
    width: 40%;
  }
  .settings_input {
    width: 100%;
  }
  .settings-grid {
    display: block;
  }
  .text-detailz {
    display: block;
  }
  .mediaCard_sec4 {
    justify-content: space-between;
  }
  .input-wrap {
    display: block;
  }
  .input--wrappa {
    width: 100%;
  }
  .input--wrappa2 {
    width: 100%;
  }
  .workers-form p {
    color: blue;
  }
  .all-employees-details {
    display: block;
  }
  .em-one {
    margin-bottom: 20px;
  }
  .notification-info {
    max-height: 250px;
  }
  .em-image img {
    height: 250px;
  }
 
}
@media (min-width: 481px) and (max-width: 767px) {
  .login-border {
    width: 40%;
    height: 97px;
  }
  .estate-box-shadow {
    display: none;
  }
  .password-icon {
    display: none;
  }
  .main-panel {
    width: 95%;
  }
  .signup-page {
    height: 600px;
  }
} 
 @media (min-width: 768px) and (max-width: 991px) {
  .rr {
    display: none;
  }
  .provide p{
    font-weight: 300;
  }
  .estate-box-shadow {
    display: none;
  }
  .main--side {
    width: 100%;
  }
  .users {
    display: none;
    width: 90%;
  }
  .users_ {
    width: 100%;
  }
  .main-panel {
    width: 95%;
  }
  .residents-details {
    display: none;
  }
  .text-detailz {
    width: 95%;
    margin: auto;
  }
  .text-detailz h4 {
    font-size: 18px;
  }
  .text-detailz input {
    padding: 10px;
    width: 100%;
    background-color: #eef3f9;
  }
  .text-detailz {
    display: block;
  }
  .pen-ding {
    width: 25%;
  }
  .pending__reports h5 {
    font-size: 21px;
  }
  .media__cards {
    display: none;
  }
  .media_card_section {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  }
  .media_card {
    margin-top: -45px;
  }
  .mediaCard_sec1 {
    height: 170px;
    justify-content: space-between;
    padding: 20px;
  }
  .mediaCard_sec2 {
    height: 170px;
    justify-content: space-between;
    padding: 20px;
  }
  .mediaCard_sec3 {
    height: 170px;
    justify-content: space-between;
    padding: 20px;
  }
  .mediaCard_sec4 {
    height: 170px;
    justify-content: space-between;
    padding: 20px;
  }
  .card-color {
    height: 55px;
    width: 20%;
  }
  .card-colorTwo {
    height: 55px;
    width: 20%;
  }
  .card-icon {
    font-size: 30px;
    padding-left: 12px;
  }
  .card-colorThr {
    height: 55px;
    width: 20%;
  }
  .card-colorFr {
    height: 55px;
    width: 20%;
  }
  .example {
    display: none;
  }
  .ham {
    display: block;
  }
  .input-field {
    width: 60%;
  }
  .input-field input {
    padding: 9px;
  }
  .artisan_left {
    width: 90%;
    display: none;
  }
  .arti-san-field {
    display: block;
  }
  .emergencyfile {
    display: block;
  }
  .atisan--input-field {
    width: 95%;
    margin: auto;
  }
  .arti-san-field {
    width: 100%;
  }
  .arti-san-field___ textarea {
    width: 100%;
  }
  .artisan_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .emergency-field {
    display: block;
  }
  .emergency--input-field {
    width: 95%;
    margin: auto;
  }
  .emergency-field {
    width: 100%;
  }
  .emergency_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .notification--input-field {
    width: 95%;
    margin: auto;
  }
  .settings_input {
    width: 100%;
  }
  .settings_input input {
    padding: 10px;
  }
  .config-button {
    width: 15%;
    font-size: 18px;
  }
  .artisan-iconone {
    width: 18%;
    height: 55px;
  }
  .artisan-iconz {
    font-size: 24px;
  }
  .artisan-iconztwo {
    font-size: 24px;
  }
  .sign--up {
    width: 95%;
  }
  .remember-me h5 {
    font-size: 12px;
    margin-top: -2px;
  }
  .password-icon {
    display: none;
  }
} 

@media (max-width: 926px) {
  .scrollable {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .rr {
    display: none;
  }
  .example {
    display: none;
  }
  .config_box {
    display: block;
  }
  .artisan-iconone {
    height: 45px;
  }
  .artisan-iconz {
    font-size: 21px;
  }
  .artisan-iconztwo {
    font-size: 21px;
  }
  .boxx_ {
    width: 100%;
  }
  .modal_form {
    display: none;
  }
  .modal_form_media {
    display: block;
    width: 100%;
  }
  .modal_form_media input {
    height: 45px;
  }
  .modal_form_media select {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .custom-datepicker {
    width: 100%;
  }
  .artisan_text textarea {
    width: 100%;
  }
  .config_border {
    max-height: 500px;
    overflow-y: auto;
  }
  .dropdown-btn {
    width: 100%;
  }
  .not-fications {
    justify-content: flex-end;
  }
  .generate_input_field {
    width: 100%;
    margin-bottom: 25px;
  }
  .generate_input_field input {
    width: 100%;
  }
  .form_details {
    display: block;
  }
  .main--side {
    width: 100%;
  }
  .media__cards {
    display: none;
  }
  .super-color {
    width: 100%;
  }
  .table__records {
    overflow-x: auto;
    margin-top: 7px;
  }
  .col {
    display: none;
  }
  .table {
    width: 100%;
  }
  .table th,
  .table td {
    white-space: nowrap;
    min-width: 100px;
  }
  .form_details {
    display: none;
  }
  .form_details_media {
    display: block;
    padding-top: 30px;
    width: 90%;
    margin: auto;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
  }
  .ham {
    display: block;
    margin-top: 10px;
  }
  .media_card {
    margin-top: -70px;
    margin-bottom: 25px;
  }
  .media_1 {
    padding: 7px;
  }
  .media_card_section {
    display: flex;
    width: 100%;
  }
  .mediaCard_sec1 {
    margin-bottom: 10px;
  }
  .mediaCard_sec3 {
    margin-bottom: 10px;
  }
  .tabsTwo {
    display: none;
  }
  .barchart {
    width: 100%;
  }
  .tabsTwwo {
    display: block;
    margin-top: -140px;
  }
  .x-box {
    width: 30%;
    height: 120px;
  }
  .pending__reports {
    margin-top: 80px;
  }
  .pending__reports h5 {
    font-size: 10px;
    font-weight: 700;
    margin-top: 2px;
  }
  .pending-box {
    height: 9px;
    width: 10%;
    margin-top: 85px;
  }
  .users {
    display: none;
  }
  .users_ {
    width: 100%;
  }
  .all_residents-inputfield {
    display: none;
  }
  .user-input-field {
    display: none;
  }
  .user-input-field_ {
    display: block;
  }
  .in-put-one {
    width: 100%;
  }
  .in-put-one input {
    width: 100%;
  }
  .all-residents__ {
    height: 270px;
  }
  .com-card-one h5 {
    font-size: 18px;
  }
  .com-card-two h5 {
    font-size: 18px;
  }
  .com-card-three h5 {
    font-size: 16px;
  }
  .residents-details {
    display: none;
  }
  .resident-media-btn {
    border: none;
    color: white;
    background: transparent;
    background-color: #669906;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: 600;
  }
  .residents-details-media {
    display: block;
    width: 100%;
  }
  .residents_button {
    font-size: 11px;
  }
  .resident-button {
    font-size: 22px;
    font-weight: 700;
  }
  .date-section {
    width: 67%;
  }
  .sel-ect {
    width: 69%;
  }
  .residents_File {
    width: 70%;
  }
  .all_residents--inputfield {
    display: block;
    margin-top: 20px;
  }
  .media_input {
    width: 95%;
    display: flex;
    gap: 15px;
  }
  .input-field {
    width: 51%;
  }
  .input-field input {
    width: 100%;
  }
  .resident-media-input input {
    width: 100%;
  }
  .media_input2 input {
    width: 90%;
  }
  .all-residents_ {
    height: 125px;
  }
  .main-panel {
    height: 700px;
    width: 100%;
  }
  .dropdown___details {
    width: 55%;
  }
  .dropdown___details_ {
    width: 83%;
  }
  .edit_section {
    width: 100%;
  }
  .dropdown___details__ {
    width: 70%;
  }
  .drop__down {
    height: 160px;
  }
  .profile-details {
    display: none;
  }
  .media_profile {
    display: block;
    width: 95%;
    margin: auto;
    margin-top: 60px;
    /* display: flex; */
  }
  .med_profile input {
    background-color: #edf3f8;
    padding: 5px 10px;
    border: 1px solid #cccccc;
    outline: none;
  }
  .all_mediainput {
    margin-top: 70px;
  }
  .profile-section {
    display: none;
  }
  .residents-border {
    height: auto;
  }
  .media__profile-section {
    width: 85%;
    margin: auto;
    margin-top: -85px;
  }
  .signup-page {
    display: none;
  }
  .sign--up {
    width: 95%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    height: 600px;
  }
  .login-border {
    width: 45%;
  }
  .login-form {
    width: 90%;
  }
  .password-icon {
    position: absolute;
    left: 75%;
    top: 47%;
    display: none;
  }
  .profile__image {
    width: 100%;
  }
  .profile__image img {
    height: 300px;
  }
  .da_te {
    width: 100%;
  }
  .da_te input {
    width: 100%;
  }
  .pen-ding {
    width: 25%;
  }
  .user-input-fieldd {
    display: block;
  }
  .artisan_left {
    display: none;
  }
  .artisan-border {
    display: none;
  }
  .artisan-border_ {
    display: block;
  }
  .artisan_details {
    display: none;
  }
  .artisan_details_ {
    display: block;
  }
  .artisanImage img {
    height: 250px;
  }
  .report__feild_ {
    width: 90%;
  }
  .radio_btn {
    width: 60%;
  }
  .sendreport-border {
    height: 105vh;
  }
  .admin--cardholder {
    display: none;
  }
  .admin__cards {
    display: block;
    width: 95%;
    margin: auto;
  }
  .admin-card_One {
    width: 100%;
  }
  .adminbaord-text {
    padding: 85px;
  }
  .sub-admin-form {
    width: 100%;
  }
  .x-box-cont {
    width: 40%;
    margin: auto;
  }
  .card-color {
    height: 28px;
    margin-top: 11px;
    width: 22%;
    margin-right: 5px;
  }
  .card-colorTwo {
    height: 28px;
    margin-top: 11px;
    width: 20%;
    margin-right: 5px;
  }
  .card-colorThr {
    height: 29px;
    margin-top: 11px;
    width: 20%;
    margin-right: 5px;
  }
  .card-colorFr {
    height: 29px;
    margin-top: 11px;
    width: 21%;
    margin-right: 5px;
  }
  .card-icon {
    padding-right: 5px;
  }
  .not-fications {
    width: 25%;
    padding-right: 5px;
  }
  .nav_settings {
    width: 7%;
  }
  .main-panel {
    width: 95%;
  }
  .border-media {
    border: 1px solid #eef3f9;
    height: auto;
    overflow: auto;
    margin-bottom: 25px;
  }
  .nav_gation {
    width: 96%;
    margin: auto;
  }
  .emergency_details {
    display: block;
  }
  .atisan--inputfield {
    width: 100%;
  }
  .arti-san-field {
    width: 100%;
  }
  .notification-field {
    width: 100%;
  }
  .notification-field input {
    background-color: #edf3f8;
    padding: 5px 10px;
    border: 1px solid #cccccc;
    outline: none;
    width: 100%;
  }
  .arti-san-field___ textarea {
    width: 100%;
  }
  .notification--input-field {
    width: 95%;
    margin: auto;
  }
  .emergency-field {
    display: block;
    width: 100%;
  }
  .emergency--input-field {
    width: 95%;
    margin: auto;
  }
  .residents_button {
    font-size: 10px;
    padding: 10px 10px;
  }
  .artisan-border {
    display: block;
  }
  .arti-san-field {
    display: block;
  }
  .atisan--input-field {
    width: 95%;
    margin: auto;
  }
  .emergencyfile {
    display: block;
  }
  .form {
    width: 95%;
    margin: auto;
  }
  .resident-image img {
    width: 100%;
    height: 200px;
  }
  .resident-image {
    width: 60%;
    margin: auto;
  }
  .forgot-component {
    width: 95%;
  }
  .password-page {
    width: 95%;
  }
  .reset-text {
    width: 90%;
  }
  .reset-input {
    width: 90%;
  }
  .text-detailz {
    width: 95%;
    margin: auto;
  }
  .text-detailz input {
    padding: 10px;
    width: 100%;
    background-color: #eef3f9;
  }
  .pending-box {
    width: 15%;
    padding: 10px;
  }
  .pending__reports h5 {
    font-size: 17px;
  }
  .pen-ding {
    width: 41%;
  }
  .check--box {
    width: 40%;
  }
  .settings_input {
    width: 100%;
  }
  .settings-grid {
    display: block;
  }
  .text-detailz {
    display: block;
  }
  .mediaCard_sec4 {
    justify-content: space-between;
  }
  .input-wrap {
    display: block;
  }
  .input--wrappa {
    width: 100%;
  }
  .input--wrappa2 {
    width: 100%;
  }
  .workers-form p {
    color: blue;
  }
  .all-employees-details {
    display: block;
  }
  .em-one {
    margin-bottom: 20px;
  }
  .notification-info {
    max-height: 250px;
  }
  .em-image img {
    height: 250px;
  }
  .login-details {
    padding-top: 60px;
  }
  .dropdown__details {
    display: flex;
    justify-content: space-between;
  }
  .dropdownEdit__details {
    width: 55%;
    display: flex;
    justify-content: space-between;
  }
  .estate-box-shadow {
    display: block;
    height: 750px;
  }
  .signup----page {
    padding-top: 100px;
  }
  /* .login-form input {
    padding: 13px;
    border-radius: 16px;
  } */
  .login--btn {
    padding: 10px;
    font-weight: 700;
    font-size: 16px;
  }
  .irivs img {
    width: 100%;
  }
  .compliance-cards{
    display: block;
  }
  .com-card-one{
    width: 100%;
  }
  .com-card-two{
    width: 100%;
  }
  .com-card-three{
    width: 100%;
  }
  
}
